import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ob-404-page')
export default class NotFoundPage extends LitElement {
    static override styles = css`
            * {
                box-sizing: border-box;
            }

            :host {
                --gap: var(--ob-gap-xl, 2rem);

                display: grid;
                place-content: center;
                height: 100%;
            }

            .page {
                margin: var(--gap);
            }
        `;

    override render() {
        return html`
            <div class="page">
                <h1>Page not found</h1>

                <p>We could not find what you are looking for.</p>

                <a href="/">Back home</a>
           </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ob-404-page': NotFoundPage;
    }
}
