declare global {
    interface Window {
        ASHORE_CONFIG: Config;
    }
}

export type Config = {
    env: 'development' | 'production' | 'staging';
    analytics_id?: string
}

const defaults: Config = {
    env: 'development',
    analytics_id: 'G-65K0QXPBPM',
};

export const config: Config = Object.assign(defaults, window.ASHORE_CONFIG || {});
