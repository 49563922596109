import { noChange } from 'lit';
import { AsyncDirective, directive } from 'lit/async-directive.js';

import type { TemplateResult } from 'lit';
import type { ChildPart } from 'lit/directive';

export function pendingStateEvent(promise: Promise<unknown>) {
    return new CustomEvent('pending-state', {
        bubbles: true,
        composed: true,
        detail: {
            promise,
        },
    });
}

class LazyLoadDirective extends AsyncDirective {

    private loaded: Map<string, Promise<unknown>> = new Map();

    private part: ChildPart;

    render(key: string, importFn: () => Promise<unknown>, htmlTemplate: TemplateResult) {
        if (!this.loaded.has(key)) {
            const promise = importFn();

            this.loaded.set(key, promise);

            this.part.parentNode.dispatchEvent(pendingStateEvent(promise));
        }

        this.loaded
            .get(key)
            .then(() => this.setValue(htmlTemplate))
            .catch(() => this.loaded.delete(key));

        // Keep the current content
        return noChange;
    }

    override update(part: ChildPart, props: Array<unknown>) {
        this.part = part;

        return super.update(part, props);
    }
}

export const lazyLoad = directive(LazyLoadDirective);
