import { gql } from 'graphql-tag';

export const GET_TENANT = gql`
    query Tenant {
        tenant {
            id
            name
            logo {
                data
                width
                height
                mediaType
            }
        }
    }
`;
