import { LitElement, css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const MOBILE_MAX_WIDTH = '540px';

@customElement('app-drawer')
export class AppDrawer extends LitElement {
    static override styles = css`
        :host {
            --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
            --duration: 0.5s;

            display: block;
            contain: content;
        }

        .drawer {
            height: 100%;
        }

        .drawer__body {
            height: 100%;
            background: #fff;
        }

        .drawer__close {
            display: none;
            all: unset;
            cursor: pointer;

            /* outline-offset: -1px;
            outline: thin solid transparent; */

            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            user-select: none;
            touch-action: manipulation;
        }

        /* .drawer__close:focus {
            outline-color: #03bdd3;
        } */

        @media (max-width: ${unsafeCSS(MOBILE_MAX_WIDTH)}) {
            :host {
                /* not keyboard accessible when closed */
                visibility: hidden;

                transform: translateX(-110vw);
                will-change: transform;
                transition:
                    transform var(--duration) var(--easeOutExpo),
                    visibility 0s linear var(--duration);

                    z-index: var(--ob-stack-drawer, 1);
            }

            :host([open]) {
                visibility: visible;
                transform: translateX(0);
                transition: transform var(--duration) var(--easeOutExpo);
            }

            .drawer {
                display: grid;
                grid-template-columns: [body] 2fr [close] 1fr;

                overflow: hidden auto;
                overscroll-behavior: contain;
            }

            .drawer__body {
                box-shadow: 5px 0 40px rgb(0 0 0 / 50%);
            }

            .drawer__close {
                display: block;
                height: 100%;
            }
        }
    `;

    private activeElement: HTMLElement;
    private mobileQuery = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH})`);

    @property({ type: Boolean, reflect: true })
    private open = !this.mobileQuery.matches;

    constructor() {
        super();

        this.addEventListener('click', this.onClick);
        this.addEventListener('keyup', this.onKeyUp);
        this.addEventListener('transitionend', this.setFocus);
    }

    override render() {
        return html`
            <div class="drawer">
                <div class="drawer__body">
                    <slot></slot>
                </div>

                <button class="drawer__close" id="close" title="Close Menu" aria-label="Close Menu"></button>
            </div>
        `;
    }

    private onKeyUp(e: KeyboardEvent) {
        if (e.key === 'Escape' && this.open) {
            this.hide();
        }
    }

    private onClick(e: Event): void {
        if (this.mobileQuery.matches) {
            (e.target as HTMLElement).blur();

            this.hide();
        }
    }

    private setFocus() {
        this.open
            ? (this.querySelector('a[href]') as HTMLElement)?.focus()
            : this.activeElement?.focus();
    }

    hide() {
        this.open = false;
    }

    show() {
        this.open = true;
    }

    toggle(el?: HTMLElement) {
        this.activeElement = el;

        this.open ? this.hide() : this.show();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-drawer': AppDrawer;
    }
}
