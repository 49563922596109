import { gql } from 'graphql-tag';

export const GET_USER = gql`
    query User {
        me {
            id
            active
            assignedRoles
            assignedShips {
                id
                name
            }
            email
            firstName
            lastName
            username
        }
    }
`;
