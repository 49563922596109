
import EventTarget from '@ungap/event-target';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import  { addSeconds, subMinutes, isBefore } from 'date-fns';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { version } from '../../../package.json';

import { CREATE_TOKEN, REFRESH_TOKEN } from './mutations/AccessToken';

import type { NormalizedCacheObject, QueryOptions, MutationOptions } from '@apollo/client/core';
import type { GraphQLError } from 'graphql';
import type {
    CreateTokenMutation,
    CreateTokenMutationVariables,
    RefreshTokenMutation,
    RefreshTokenMutationVariables,
} from '../../types.generated';

export class GraphQLClient extends EventTarget {

    // The Appollo Client instance for reagular use.
    readonly apolloClient: ApolloClient<NormalizedCacheObject>;

    // Apollo Client instance used to perform the mutations to login and refresh tokens.
    // This is to prevent ending up in an endless loop. Where it requests an access token
    // to request an access token wich will also want an access token...
    private authenticationClient: ApolloClient<NormalizedCacheObject>;

    // Singleton Promise for getting the access token.
    // https://www.jonmellman.com/posts/singleton-promises
    private accessToken: Promise<AccessToken> | null = null;

    static isUnauthorizedError(error: GraphQLError): boolean {
        return /Subject OAuthSubject\(.*\) is not authorized/.test(error.message);
    }

    constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
        super();

        this.apolloClient = apolloClient;

        this.authenticationClient = new ApolloClient({
                cache: new InMemoryCache(),
                uri: '/v1/graphql',
                name: `ashore-auth-${new Date().getMilliseconds()}`,
                version: version,
                defaultOptions: {
                    query: {
                        fetchPolicy: 'no-cache',
                    },
                },
            });
    }

    get refreshToken() {
        return window.localStorage.getItem('refreshToken');
    }

    set refreshToken(refreshToken) {
        window.localStorage.setItem('refreshToken', refreshToken);
    }

    async getAccessToken(): Promise<string> {
        if (await this.isAccessTokenExpired()) {
            this.accessToken = this.refresh();
        }
        return (await this.accessToken).accessToken;
    }

    private async isAccessTokenExpired(): Promise<boolean> {
        if (this.accessToken != null) {
            return (await this.accessToken).isExpired();
        }
        return true;
    }

    async query(options: QueryOptions): Promise<any> {
        return this.apolloClient.query(options);
    }

    async mutate(options: MutationOptions): Promise<any> {
        return this.apolloClient.mutate(options);
    }

    async login(username: string, password: string): Promise<AccessToken> {
        return this.accessToken = this.doLogin(username, password);
    }

    private async doLogin(username: string, password: string): Promise<AccessToken> {
        const variables: CreateTokenMutationVariables = {
            username,
            password,
        };
        return this.authenticationClient.mutate({
            mutation: CREATE_TOKEN,
            variables,
            errorPolicy: 'none',
        }).then(({ data: { createToken } }: { data: CreateTokenMutation }) => {
            const {accessToken, refreshToken, expiresIn} = createToken;
            this.refreshToken = refreshToken;
            return new AccessToken(
                accessToken,
                expiresIn,
            );
        });
    }

    async logout(): Promise<void> {
        this.apolloClient.stop();
        this.reset();
    }

    async refresh(): Promise<AccessToken> {
        return this.accessToken = this.doRefresh();
    }

    private async doRefresh(): Promise<AccessToken> {
        const variables: RefreshTokenMutationVariables = {
            refreshToken: this.refreshToken,
        };
        return this.authenticationClient.mutate({
            mutation: REFRESH_TOKEN,
            variables,
            errorPolicy: 'none',
        }).then(({ data: { refreshToken } }: { data: RefreshTokenMutation }) => {
            const {accessToken: accessToken, refreshToken: refreshedToken, expiresIn: expiresIn} = refreshToken;
            this.refreshToken = refreshedToken;
            return new AccessToken(
                accessToken,
                expiresIn,
            );
        });
    }

    reset() {
        this.accessToken = null;
        this.refreshToken = '';

        this.apolloClient.clearStore();
    }
}

export class AccessToken {
    readonly accessToken: string;
    readonly accessTokenExpiration: Date;

    constructor(accessToken: string, expiresIn: number) {
        this.accessToken = accessToken;
        this.accessTokenExpiration = subMinutes(addSeconds(new Date(), expiresIn),  2);
    }

    isExpired(): boolean {
        if (this.accessTokenExpiration) {
            return !isBefore(new Date(), this.accessTokenExpiration);
        }
        return true;
    }
}

const authLink = setContext(async (operation) => {
    const token = await client.getAccessToken();
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
});

const errorLink = onError(({ graphQLErrors = [], networkError, operation, forward }) => {

    for (const error of graphQLErrors) {
        console.log(`[GraphQL error]: ${JSON.stringify(error)}`);
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const httpLink = createHttpLink({
    uri: '/v1/graphql',
});

const apolloClient = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        errorLink,
        httpLink,
    ]),
    cache: new InMemoryCache(),
    name: 'ashore',
    version: version,
    credentials: 'omit',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
});

export const client: GraphQLClient = new GraphQLClient(apolloClient);
