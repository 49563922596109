import gql from 'graphql-tag';

export const GET_SHIPS = gql`
    query Ships {
        ships {
            id
            key
            name
        }
    }
`;

export const SHIP_POSITION = gql`
    query ShipPosition($ships: ShipFilter, $position: MeasurementFilter) {
        ships(where: $ships) {
            id
            name
            position(where: $position) {
                track {
                    dateTime
                    latitude
                    longitude
                }
            }
            activities {
                type {
                    name
                    icon
                }
                voyage {
                    destinationLocation
                    pickUpLocation
                }
            }
        }
    }
`;
