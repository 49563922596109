import type { Workbox, WorkboxLifecycleEvent } from 'workbox-window';

const USE_SERVICE_WORKER = process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator;

let workbox: Workbox;

export async function register(onUpdate: () => Promise<void>): Promise<Workbox | undefined> {
    if (!USE_SERVICE_WORKER) {
        return;
    }

    const { Workbox } = await import('workbox-window');

    workbox = new Workbox('/service-worker.js');

    const showSkipWaitingPrompt = (e: WorkboxLifecycleEvent) => {
        // `event.wasWaitingBeforeRegister` will be false if this is
        // the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously
        // updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.

        onUpdate().then(() => {
            // Assuming the user accepted the update, set up a listener
            // that will reload the page as soon as the previously waiting
            // service worker has taken control.
            workbox.addEventListener('controlling', (e) => {
                window.location.reload();
            });

            workbox.messageSkipWaiting();
        });
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    workbox.addEventListener('waiting', showSkipWaitingPrompt);

    await workbox.register();

    return workbox;
}

export function update(): Promise<void> {
    return workbox?.update();
}
