import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

// Import styles
import './styles/main.scss';
import './app/styles/main.scss';

import { name, version } from '../package.json';
import { config } from './config';

import './app/app';

const  { env = 'development' } = config;

if (env === 'staging' || env === 'production') {
    Sentry.init({
        dsn: 'https://4a537e00633e4760ade35f98b33b6a4b@o1002612.ingest.sentry.io/5962825',
        environment: env,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        release: `${name}@${version}`,
        tracesSampleRate: 1.0,
    });
}
