import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import 'onboard-shipyard/dropdown';
import 'onboard-shipyard/menu';
import 'onboard-shipyard/icon';

import { UserController } from './controllers/user';

@customElement('app-header')
export class AppHeader extends LitElement {

    #user = new UserController(this);

    @state()
    private get disableUserProfile(): boolean {
        return !this.#user.isFleetManager() || this.#user.isAdmin();
    }

    override render() {
        const { fullName } = this.#user;

        return html`
             <header>
                <a href="#drawer" class="button-menu" @click="${this.onMenuClick}" title="Toggle Menu" aria-label="Toggle Menu">
                    <ob-icon name="list"></ob-icon>
                </a>

                <a href="/app/dashboard" class="logo"><img src="/images/onboard-logo-white.svg" alt="Onboard"></a>

                <div class="user">
                    <ob-dropdown position="right">
                        <ob-button slot="trigger" class="button" title="${fullName}" type="text">
                            <ob-icon name="person-circle" slot="prefix" size="small"></ob-icon>
                            <span class="name">${this.#user.state.username}</span>
                        </ob-button>

                        <ob-menu class="menu">
                            <ob-menu-label>${fullName}</ob-menu-label>
                            <ob-menu-item value="user-profile" ?disabled="${this.disableUserProfile}">Profile <ob-icon name="person-circle" slot="suffix"></ob-icon></ob-menu-item>
                            <ob-menu-divider></ob-menu-divider>
                            <ob-menu-item value="user-logout">Log out <ob-icon name="box-arrow-right" slot="suffix"></ob-icon></ob-menu-item>
                        </ob-menu>
                    </ob-dropdown>
                </div>
            </header>
        `;
    }

    // Render in light DOM because Angular (router) cancels event handlers in the Shadow DOM.
    override createRenderRoot() {
        return this;
    }

    private onMenuClick(e: Event) {
        e.preventDefault();

        this.dispatchEvent(new CustomEvent('nav-toggle', {
            bubbles: true,
            composed: true,
            detail: {
                el: e.target,
            },
        }));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-header': AppHeader;
    }
}
