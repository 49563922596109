import 'form-request-submit-polyfill';

import { LitElement, html } from 'lit';
import {
    customElement,
    property,
    query,
    queryAll,
    state,
} from 'lit/decorators.js';

import page from 'page';

import { version } from '../../package.json';

import { lazyLoad, pendingStateEvent } from './directives/lazy-load';

import { client } from './graphql/client';
import { UserController } from './controllers/user';
import { register, update } from './register-service-worker';

import 'onboard-shipyard/alert';
import 'onboard-shipyard/icon';
import 'onboard-shipyard/button';
import 'onboard-shipyard/spinner';

import '@material/mwc-linear-progress';

import './pages/404';
import './app-drawer';
import './app-header';

import type { Context, Static } from 'page';
import type { OnboardAlert } from 'onboard-shipyard';
import type { OnboardMenuItem } from 'onboard-shipyard';
import type { AppDrawer } from './app-drawer';
import type { GraphQLClient } from './graphql/client';

@customElement('ob-shore-app')
export class ShoreApp extends LitElement {
    #client: GraphQLClient = client;

    #router: Static = page;

    #user = new UserController(this);

    @property()
    get pageTitle() {
        return document.title;
    }

    set pageTitle(value: string) {
        document.title = value + ' - Onboard';
    }

    @state()
    private get loading(): boolean {
        return this.pendingCount > 0;
    }

    @state()
    private location: Context;

    @state()
    private page: string | null;

    @state()
    private pendingCount = 0;

    @query('.toast-wrapper')
    private toastWrapper: HTMLElement;

    @query('#drawer')
    private drawer: AppDrawer;

    @queryAll('.nav__item')
    private navItems: HTMLAnchorElement[];

    constructor() {
        super();

        // Bind callbacks
        this.onRoute = this.onRoute.bind(this);
        this.validateUser = this.validateUser.bind(this);
        this.onServiceWorkerUpdate = this.onServiceWorkerUpdate.bind(this);

        this.installRoutes();

        this.addEventListener('login-success', this);
        this.addEventListener('nav-toggle', this);
        this.addEventListener('menu-select', this);
        this.addEventListener('pending-state', this);
        this.addEventListener('router-update', this);

        // Logout in case the GraphQL session is expired
        this.#client.addEventListener('graphql:login', () =>
            this.sessionExpired(),
        );
    }

    override connectedCallback() {
        super.connectedCallback();

        register(this.onServiceWorkerUpdate);
    }

    /**
     * handleEvent
     * Super efficient event handling available to all EventTargets.
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/EventListener/handleEvent}
     * @param e
     */
    handleEvent(e: CustomEvent): void {
        switch (e.type) {
            case 'login-success':
                this.onLoginSuccess(e);
                break;
            case 'nav-toggle':
                this.drawer.toggle(e.detail.el as HTMLElement);
                break;
            case 'menu-select':
                this.onMenuSelect(e);
                break;
            case 'pending-state':
                this.onPendingState(e);
                break;
            case 'router-update':
                this.onRouterUpdate(e);
                break;
        }
    }

    // Fetch the user details and then go to the dashboard.
    private async onLoginSuccess(e: CustomEvent) {
        try {
            // Clear the login page
            this.page = null;

            // Fetch the user details
            await this.#user.fetchUser();
        } finally {
            // And redirect to the returnPath
            this.#router.redirect(e.detail.returnPath ?? '/app/dashboard');
        }
    }

    private onMenuSelect(e: CustomEvent) {
        const { item }: { item: OnboardMenuItem } = e.detail;

        switch (item.value) {
            case 'user-logout':
                this.logout();
                break;
            case 'user-profile':
                this.#router.show(
                    `/app/settings/users/${this.#user.state.id}/details`,
                );
                break;
            default:
                console.log('menu-select:', item);
        }
    }

    private async onPendingState(e: CustomEvent) {
        // First wait for the elements update to complete
        await this.updateComplete;

        // Then trigger the next updates
        this.pendingCount++;

        try {
            await e.detail.promise;
        } catch (err) {
            this.setPage('not-found', 'Page not found');
        } finally {
            this.pendingCount--;
        }
    }

    private onRouterUpdate(e: CustomEvent) {
        const { path, dispatch = false, replace = false } = e.detail;

        if (replace) {
            this.#router.replace(path, null, false, dispatch);
        } else {
            // @ts-ignore: https://github.com/visionmedia/page.js/blob/master/page.mjs#L594
            this.#router.show(path, null, dispatch);
        }
    }

    private onServiceWorkerUpdate(): Promise<void> {
        return new Promise<void>((resolve) => {
            const alert = this.#notify(
                `There is a new version available. Reload to update.
                <ob-button slot="action" value="reload">Reload</ob-button>`,
                'info-circle',
            );

            alert.addEventListener('ob-alert-action', () => resolve(), {
                once: true,
            });
        });
    }

    private installRoutes() {
        this.#router('*', this.onRoute);
        this.#router('/', () => {
            this.#user.isActive
                ? this.#router.redirect('/app/dashboard')
                : this.#router.redirect('/login');
        });
        this.#router('/login', () => this.setPage('login', 'Login'));
        this.#router('/password-reset', () =>
            this.setPage('password-reset', 'Password reset'),
        );
        this.#router('/reset-credential', () =>
            this.setPage('password-update', 'Password update'),
        );
        this.#router('/app', () => this.#router.redirect('/app/dashboard'));
        this.#router('/app/dashboard', () =>
            this.setPage('dashboard', 'Dashboard'),
        );
        this.#router('/app/schedule', () =>
            this.setPage('schedule', 'Schedule'),
        );
        this.#router('/app/logbook', () => this.setPage('logbook', 'Logbook'));
        this.#router('/app/consumption', () =>
            this.setPage('consumption', 'Fuel consumption'),
        );
        this.#router('/app/efficiency', () =>
            this.setPage('efficiency', 'Fuel efficiency'),
        );
        this.#router('/app/efficiency/ship/:id', () =>
            this.setPage('efficiency-details', 'Fuel efficiency'),
        );
        this.#router('/app/settings(/?)(.*)', (context, next) => {
            this.#user.isFleetManager()
                ? this.setPage('settings', 'Settings')
                : this.#router.redirect('/app/dashboard');
        });
        this.#router('*', () => this.setPage('not-found', 'Page not found'));
        this.#router();
    }

    // Render in light DOM because of the Angular components
    override createRenderRoot() {
        return this;
    }

    override render() {
        return html`
            ${this.renderLayout()}
            <div class="toast-wrapper">
                <!-- reserved for toasts -->
            </div>
        `;
    }

    renderLayout() {
        const { tenant } = this.#user.state;

        const logo = html`
            ${tenant.logo
                ? html`
                      <img
                          class="logo"
                          alt="${tenant.name}"
                          src="data:${tenant.logo.mediaType};base64, ${tenant
                              .logo.data}"
                      />
                      <span class="tenant">${tenant.name}</span>
                  `
                : html`<span class="tenant">${tenant.name}</span>`}
        `;

        return this.#user.isActive
            ? html`
                  <div class="app">
                      <mwc-linear-progress
                          class="app__loader"
                          ?closed="${!this.loading}"
                          indeterminate
                      ></mwc-linear-progress>
                      <app-header class="app__header"></app-header>
                      <app-drawer class="app__drawer" id="drawer">
                          <nav class="nav">
                              <h1 class="nav__header">${logo}</h1>
                              <a class="nav__item" href="/app/dashboard"
                                  ><ob-icon
                                      class="icon"
                                      name="menuDashboard"
                                  ></ob-icon>
                                  Dashboard</a
                              >
                              <a class="nav__item" href="/app/schedule"
                                  ><ob-icon
                                      class="icon"
                                      name="menuSchedule"
                                  ></ob-icon>
                                  Schedule</a
                              >
                              <a class="nav__item" href="/app/logbook"
                                  ><ob-icon
                                      class="icon"
                                      name="menuLog"
                                  ></ob-icon>
                                  Logbook</a
                              >
                              <div class="nav__group">
                                  <h2 class="nav__header nav__header--sub">
                                      Fuel
                                  </h2>
                                  <a
                                      class="nav__item nav__item--sub"
                                      href="/app/consumption"
                                      ><ob-icon
                                          class="icon"
                                          name="coin"
                                      ></ob-icon>
                                      Consumption</a
                                  >
                                  <a
                                      class="nav__item nav__item--sub"
                                      href="/app/efficiency"
                                      ><ob-icon
                                          class="icon"
                                          name="menuAnalysis"
                                      ></ob-icon>
                                      Efficiency</a
                                  >
                              </div>
                              ${this.#user.isFleetManager()
                                  ? html`<a
                                        class="nav__item"
                                        href="/app/settings"
                                        ><ob-icon
                                            class="icon"
                                            name="menuSettings"
                                        ></ob-icon>
                                        Settings</a
                                    >`
                                  : null}
                              <div class="nav__version">v${version}</div>
                          </nav>
                      </app-drawer>
                      <main class="app__main app__main--${this.page}">
                          ${this.renderPage()}
                      </main>
                  </div>
              `
            : html`<main>${this.renderPage()}</main>`;
    }

    renderPage() {
        switch (this.page) {
            case 'login':
                return lazyLoad(
                    this.page,
                    () => import('./pages/login'),
                    html`<ob-login-page></ob-login-page>`,
                );
            case 'password-reset':
                return lazyLoad(
                    this.page,
                    () => import('./pages/password-reset'),
                    html`<ob-password-reset-page></ob-password-reset-page>`,
                );
            case 'password-update':
                return lazyLoad(
                    this.page,
                    () => import('./pages/password-update'),
                    html`<ob-password-update-page></ob-password-update-page>`,
                );
            case 'dashboard':
                return lazyLoad(
                    this.page,
                    () => import('./pages/home'),
                    html`<ob-home-page></ob-home-page>`,
                );
            case 'schedule':
                return lazyLoad(
                    this.page,
                    () => import('./pages/schedule'),
                    html`<ob-schedule-page
                        .location="${this.location}"
                    ></ob-schedule-page>`,
                );
            case 'logbook':
                return lazyLoad(
                    this.page,
                    () => import('./pages/logbook'),
                    html`<ob-logbook-page
                        .location="${this.location}"
                    ></ob-logbook-page>`,
                );
            case 'consumption':
                return lazyLoad(
                    this.page,
                    () => import('./pages/fuel-consumption'),
                    html`<ob-fuel-consumption></ob-fuel-consumption>`,
                );
            case 'efficiency':
                return lazyLoad(
                    this.page,
                    () => import('./pages/fuel-efficiency'),
                    html`<ob-fuel-efficiency></ob-fuel-efficiency>`,
                );
            case 'settings':
                return lazyLoad(
                    this.page,
                    () => import('./pages/settings'),
                    html`<ob-settings-page></ob-settings-page>`,
                );
            case 'not-found':
                return html`<ob-404-page></ob-404-page>`;
            default:
                return html`<ob-spinner mask></ob-spinner>`;
        }
    }

    override updated(props: Map<string, unknown>) {
        this.setActiveNav();
    }

    private setPage(page: string, title: string): void {
        this.page = page;
        this.pageTitle = title;
    }

    /**
     * Route change handler
     * Sets the location.
     * Updates the Service Worker.
     * And validates the session.
     *
     * @param context
     * @param next
     */
    private onRoute(context: Context, next: any) {
        update();

        this.location = context;

        this.validateUser(context, next);
    }

    private setActiveNav(): void {
        for (const item of this.navItems) {
            const isActive = this.location.pathname.includes(
                (item as HTMLAnchorElement).pathname,
            );

            item.classList.toggle('active', isActive);
        }
    }

    /**
     * Validate the user on page refresh.
     * Redirect to the login when it fails.
     * @param context
     * @param next
     * @returns Promise<void>
     */
    private async validateUser(context: Context, next: any): Promise<void> {
        if (
            context.init &&
            (location.pathname === '/' || location.pathname.includes('/app'))
        ) {
            if (await this.restoreUserSession()) {
                return next();
            }

            const { pathname, search } = window.location;

            const path =
                pathname !== '/' || !!search
                    ? `/login?returnPath=${encodeURIComponent(
                          pathname + search,
                      )}`
                    : '/login';

            return this.#router.redirect(path);
        }

        next();
    }

    private async restoreUserSession(): Promise<boolean> {
        try {
            await this.#client.refresh();
            await this.#user.fetchUser();

            return true;
        } catch (err) {
            return false;
        }
    }

    /**
     * Notify the user of certain events.
     * Private function to prevent XSS attacks.
     * @param html The html content to show
     * @param icon optional icon
     * @param duration optional auto hide
     * @returns OnboardAlert
     */
    #notify(html: string, icon?: string, duration?: number): OnboardAlert {
        const alert: OnboardAlert = Object.assign(
            document.createElement('ob-alert'),
            {
                type: 'toast',
                duration,
                icon,
                innerHTML: html,
            },
        );

        this.toastWrapper.append(alert);

        alert.show();

        return alert;
    }

    async logout(): Promise<void> {
        const promise = this.#user.logout().then(() => {
            // Reset the user
            this.#user.reset();

            // And go to the login page
            this.#router.show('/login');
        });

        this.dispatchEvent(pendingStateEvent(promise));
    }

    sessionExpired() {
        this.#notify('Your session has expired.', 'exclamation-circle', 4000);

        this.logout();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ob-shore-app': ShoreApp;
    }
}
