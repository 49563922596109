import type { ReactiveController, ReactiveControllerHost } from 'lit';
import type { Ship, Tenant, User } from '../../types.generated';
import type { AccessToken, GraphQLClient } from '../graphql/client';

import { client } from '../graphql/client';

import { GET_USER } from '../graphql/queries/User';
import { GET_SHIPS } from '../graphql/queries/Ship';
import { GET_TENANT } from '../graphql/queries/Tenant';

import { AuthorizationRole } from '../../types.generated';

export const FLEET_MANAGER_ROLES: AuthorizationRole[] = [
    AuthorizationRole.Admin,
    AuthorizationRole.FleetManager,
];

export type UserState = User & {
    ships: Ship[];
    tenant: Tenant;
}

const DEFAULT_USER: UserState = {
    id: null,
    active: false,
    assignedRoles: [],
    ships: [],
    tenant: {
        id: null,
        name: '',
        logo: null,
    },
};

export class UserController implements ReactiveController {

    static #hosts: Set<ReactiveControllerHost> = new Set();

    static #state: UserState = {
        ...DEFAULT_USER,
    };

    #client: GraphQLClient = client;

    /**
     * Make the host optional so that we can use this single store in the Angular Components/Controllers
     * @param host LitElement
     */
    constructor(private host?: ReactiveControllerHost) {
        this.host?.addController(this as ReactiveController);
    }

    hostConnected() {
        if (this.host) {
            UserController.#hosts.add(this.host);
        }
    }

    hostDisconnected() {
        if (this.host) {
            UserController.#hosts.delete(this.host);
        }
    }

    reset(state: UserState = DEFAULT_USER) {
        this.state = state;
    }

    update(data: Partial<UserState>) {
        this.state = Object.assign(this.state, data);
    }

    set state(state: UserState) {
        UserController.#state = { ...state };

        for (const host of UserController.#hosts) {
            host.requestUpdate();
        }
    }

    get state(): UserState {
        return UserController.#state;
    }

    get fullName(): string {
        return `${this.state.firstName} ${this.state.lastName}`;
    }

    get isActive(): boolean {
        return !!(this.state.id && this.state.active);
    }

    get ships(): Ship[] {
        return this.state.ships ?? [];
    }

    async fetchUser(): Promise<void> {
        const [
            _user,
            _ships,
            _tenant,
        ] = await Promise.all([
            this.#client.query({ query: GET_USER }),
            this.#client.query({ query: GET_SHIPS }),
            this.#client.query({ query: GET_TENANT }),
        ]);

        const { me }: { me: User, tenant: Tenant } = _user.data;
        const { ships }: { ships: Ship[] } = _ships.data;
        const { tenant }: { tenant: Tenant } = _tenant.data;

        const state: UserState = Object.assign(me, { tenant }, { ships });

        this.reset(state);
    }

    hasRole(roles: AuthorizationRole | AuthorizationRole[]): boolean {
        if (!(this.state && roles)) {
            return false;
        }

        if (!Array.isArray(roles)) {
            roles = [roles];
        }

        return this.state.assignedRoles.some((role) => roles.includes(role));
    }

    isAdmin(): boolean {
        return this.hasRole(AuthorizationRole.Admin);
    }

    isFleetManager(): boolean {
        return this.hasRole(FLEET_MANAGER_ROLES);
    }

    // Login with the GraphQL API and the old REST API.
    async login(username: string, password: string): Promise<[AccessToken, void]> {
        const params = new URLSearchParams({
            accountId: username,
            password,
            providerType: 'local',
            privateDevice: false,
            relogin: false,
            userAgent: navigator.userAgent,
        } as any); // https://github.com/microsoft/TypeScript/issues/32951

        return Promise.all([
            this.#client.login(username, password),
            fetch('/auth/rest/login', {
                method: 'POST',
                credentials: 'include',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: params.toString(),
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(res.statusText);
                    }
                }),
        ]);
    }

    // Logout with the REST API.
    // Then request an url that requires authentication,
    // this will return a 403 and will expire the `onboard_auth` cookie.
    // And in the meantime logout the GraphQL client
    async logout(): Promise<[Response, void]> {
        return fetch('/auth/rest/logout', {
            method: 'POST',
            credentials: 'include',
        })
            .then(() => Promise.all([
                // This will return the 403 and expire the cookie
                fetch('/shore/rest/tenantconfig', {
                    credentials: 'include',
                }),
                this.#client.logout(),
            ]));
    }
}
