import { gql } from 'graphql-tag';

export const CREATE_TOKEN = gql`
    mutation CreateToken($username: String!, $password: String!) {
        createToken(username: $username, password: $password) {
            accessToken,
            refreshToken,
            expiresIn
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($refreshToken: String!){
        refreshToken(refreshToken: $refreshToken) {
            accessToken,
            refreshToken,
            expiresIn
        }
    }
`;
